import { defineMessages } from 'utils/intl';

export default defineMessages({
  configuration: 'Konfiguracja',
  more: 'Dowiedz się więcej',
  configurationDescription: `Dajemy Ci do dyspozycji nasz autorski konfigurator, który umożliwia skorzystanie z pełnej oferty Dell.
    Zbuduj konfigurację samodzielnie lub skontaktuj się telefonicznie i skorzystaj z pomocy naszych specjalistów!`,
  configurationLongDescription: `Wszystkie modyfikacje sprzętu odbywają się w fabryce producenta i podlegają fabrycznej gwarancji.
    Neque rutrum turpis dolor nibh libero ligula. Eu dictum quis pharetra sed.
    Enim at arcu sit sed eleifend tempus dignissim nunc vitae.
    Tempus nec ullamcorper scelerisque neque turpis in condimentum nulla donec.
    Ac id netus quam urna dictum orci imperdiet.
    Sagittis massa ipsum dolor ullamcorper consectetur placerat tortor dolor.
    In pellentesque massa nulla amet neque diam dis aliquam.
    Arcu scelerisque fermentum diam dui. Sagittis id tristique fringilla commodo ipsum.
    Morbi velit maecenas mattis non felis sed. In interdum gravida quis nunc.
    Wszystkie modyfikacje sprzętu odbywają się w fabryce producenta i podlegają fabrycznej gwarancji
    Neque rutrum turpis dolor nibh libero ligula. Eu dictum quis pharetra sed.
    Enim at arcu sit sed eleifend tempus dignissim nunc vitae. Tempus nec ullamcorper scelerisque neque turpis in condimentum nulla donec.
    Ac id netus quam urna dictum orci imperdiet. Sagittis massa ipsum dolor ullamcorper consectetur placerat tortor dolor.
    In pellentesque massa nulla amet neque diam dis aliquam. Arcu scelerisque fermentum diam dui.
    Sagittis id tristique fringilla commodo ipsum. Morbi velit maecenas mattis non felis sed. In interdum gravida quis nunc.
    Wszystkie modyfikacje sprzętu odbywają się w fabryce producenta i podlegają fabrycznej gwarancji.
    Neque rutrum turpis dolor nibh libero ligula. Eu dictum quis pharetra sed.
    Enim at arcu sit sed eleifend tempus dignissim nunc vitae. Tempus nec ullamcorper scelerisque neque turpis in condimentum nulla donec.
    Ac id netus quam urna dictum orci imperdiet. Sagittis massa ipsum dolor ullamcorper consectetur placerat tortor dolor.
    In pellentesque massa nulla amet neque diam dis aliquam. Arcu scelerisque fermentum diam dui.
    Sagittis id tristique fringilla commodo ipsum. Morbi velit maecenas mattis non felis sed. In interdum gravida quis nunc.`,
  pricing: 'Wycena',
  pricingDescription: `Razem z Dell przygotujemy dla Ciebie indywidualną wycenę.
    Najwyższy status partnera Dell pozwala nam na zaproponowanie najlepszej możliwej ceny na rynku.`,
  pricingLongDescription: `Razem z Dell przygotujemy dla Ciebie indywidualną wycenę,
    najwyższy status partnera dell pozwala nam na zaproponowanie najlepszej możliwej ceny na rynku.
    Neque rutrum turpis dolor nibh libero ligula. Eu dictum quis pharetra sed.
    Enim at arcu sit sed eleifend tempus dignissim nunc vitae.
    Tempus nec ullamcorper scelerisque neque turpis in condimentum nulla donec. Ac id netus quam urna dictum orci imperdiet.
    Sagittis massa ipsum dolor ullamcorper consectetur placerat tortor dolor. In pellentesque massa nulla amet neque diam dis aliquam.
    Razem z Dell przygotujemy dla Ciebie indywidualną wycenę,
    najwyższy status partnera dell pozwala nam na zaproponowanie najlepszej możliwej ceny na rynku.
    Neque rutrum turpis dolor nibh libero ligula. Eu dictum quis pharetra sed. Enim at arcu sit sed eleifend tempus dignissim nunc vitae.
    Tempus nec ullamcorper scelerisque neque turpis in condimentum nulla donec. Ac id netus quam urna dictum orci imperdiet.
    Sagittis massa ipsum dolor ullamcorper consectetur placerat tortor dolor. In pellentesque massa nulla amet neque diam dis aliquam.
    Razem z Dell przygotujemy dla Ciebie indywidualną wycenę,
    najwyższy status partnera dell pozwala nam na zaproponowanie najlepszej możliwej ceny na rynku.
    Neque rutrum turpis dolor nibh libero ligula. Eu dictum quis pharetra sed. Enim at arcu sit sed eleifend tempus dignissim nunc vitae.
    Tempus nec ullamcorper scelerisque neque turpis in condimentum nulla donec. Ac id netus quam urna dictum orci imperdiet.
    Sagittis massa ipsum dolor ullamcorper consectetur placerat tortor dolor. In pellentesque massa nulla amet neque diam dis aliquam.`,
  production: 'Produkcja',
  productionDescription: `Twoje zamówienie zostanie zrealizowane specjalnie dla Ciebie poprzez oficjalny kanał dystrybucji Dell w Polsce
    - dzięki temu zyskujesz pełną gwarancję pochodzenia.`,
  productionLongDescription: `Serwery oraz komputery stacjonarne produkowane są w fabryce Dell w Łodzi..
    Arcu sit ipsum amet integer faucibus tempor dolor convallis. Eleifend ut quis netus ullamcorper sed in fermentum.
    Neque rutrum turpis dolor nibh libero ligula. Eu dictum quis pharetra sed.
    Enim at arcu sit sed eleifend tempus dignissim nunc vitae. Tempus nec ullamcorper scelerisque neque turpis in condimentum nulla donec.
    Ac id netus quam urna dictum orci imperdiet. Sagittis massa ipsum dolor ullamcorper consectetur placerat tortor dolor.
    In pellentesque massa nulla amet neque diam dis aliquam. Arcu scelerisque fermentum diam dui.
    Sagittis id tristique fringilla commodo ipsum. Morbi velit maecenas mattis non felis sed. In interdum gravida quis nunc.
    Serwery oraz komputery stacjonarne produkowane są w fabryce Dell w Łodzi.. Arcu sit ipsum amet integer faucibus tempor dolor convallis.
    Eleifend ut quis netus ullamcorper sed in fermentum. Neque rutrum turpis dolor nibh libero ligula. Eu dictum quis pharetra sed.
    Enim at arcu sit sed eleifend tempus dignissim nunc vitae. Tempus nec ullamcorper scelerisque neque turpis in condimentum nulla donec.
    Ac id netus quam urna dictum orci imperdiet. Sagittis massa ipsum dolor ullamcorper consectetur placerat tortor dolor.
    In pellentesque massa nulla amet neque diam dis aliquam. Arcu scelerisque fermentum diam dui. Sagittis id tristique fringilla commodo ipsum.
    Morbi velit maecenas mattis non felis sed. In interdum gravida quis nunc.
    Serwery oraz komputery stacjonarne produkowane są w fabryce Dell w Łodzi..
    Arcu sit ipsum amet integer faucibus tempor dolor convallis. Eleifend ut quis netus ullamcorper sed in fermentum.
    Neque rutrum turpis dolor nibh libero ligula. Eu dictum quis pharetra sed. Enim at arcu sit sed eleifend tempus dignissim nunc vitae.
    Tempus nec ullamcorper scelerisque neque turpis in condimentum nulla donec. Ac id netus quam urna dictum orci imperdiet.
    Sagittis massa ipsum dolor ullamcorper consectetur placerat tortor dolor. In pellentesque massa nulla amet neque diam dis aliquam.
    Arcu scelerisque fermentum diam dui. Sagittis id tristique fringilla commodo ipsum. Morbi velit maecenas mattis non felis sed.
    In interdum gravida quis nunc.`,
  shipment: 'Dostawa',
  shipmentDescription: `Większość urządzeń jest produkowana w fabryce Dell w Łodzi -
    pozwala nam to na maksymalne skrócenie łańcucha dostaw i realizację zamówień w najkrótszym możliwym czasie.`,
  shipmentLongDescription: `Jednorazowo dostarczamy nawet kilkaset urządzeń gotowych do pracy.
    Eu dictum quis pharetra sed. Enim at arcu sit sed eleifend tempus dignissim nunc vitae.
    Tempus nec ullamcorper scelerisque neque turpis in condimentum nulla donec. Ac id netus quam urna dictum orci imperdiet.
    Sagittis massa ipsum dolor ullamcorper consectetur placerat tortor dolor. In pellentesque massa nulla amet neque diam dis aliquam.
    Arcu scelerisque fermentum diam dui. Sagittis id tristique fringilla commodo ipsum. Morbi velit maecenas mattis non felis sed.
    In interdum gravida quis nunc. Jednorazowo dostarczamy nawet kilkaset urządzeń gotowych do pracy. Eu dictum quis pharetra sed.
    Enim at arcu sit sed eleifend tempus dignissim nunc vitae. Tempus nec ullamcorper scelerisque neque turpis in condimentum nulla donec.
    Ac id netus quam urna dictum orci imperdiet. Sagittis massa ipsum dolor ullamcorper consectetur placerat tortor dolor.
    In pellentesque massa nulla amet neque diam dis aliquam. Arcu scelerisque fermentum diam dui.
    Sagittis id tristique fringilla commodo ipsum. Morbi velit maecenas mattis non felis sed.
    In interdum gravida quis nunc. Jednorazowo dostarczamy nawet kilkaset urządzeń gotowych do pracy.
    Eu dictum quis pharetra sed. Enim at arcu sit sed eleifend tempus dignissim nunc vitae.
    Tempus nec ullamcorper scelerisque neque turpis in condimentum nulla donec. Ac id netus quam urna dictum orci imperdiet.
    Sagittis massa ipsum dolor ullamcorper consectetur placerat tortor dolor. In pellentesque massa nulla amet neque diam dis aliquam.
    Arcu scelerisque fermentum diam dui. Sagittis id tristique fringilla commodo ipsum. Morbi velit maecenas mattis non felis sed.
    In interdum gravida quis nunc.`,
  implementation: '{bold} (opcjonalne)',
  implementationBold: 'Wdrożenie',
  implementationDescription: `Certyfikowany zespół inżynierów i 30-letnie doświadczenie na rynku pozwala zaoferować usługi wdrożeniowe
    poczynając od pojedynczych instalacji po najbadziej rozbudowane i zaawansowane rozwiązania.`,
  implementationLongDescription: `Eu dictum quis pharetra sed. Enim at arcu sit sed eleifend tempus dignissim nunc vitae.
  Tempus nec ullamcorper scelerisque neque turpis in condimentum nulla donec.
  Ac id netus quam urna dictum orci imperdiet. Sagittis massa ipsum dolor ullamcorper consectetur placerat tortor dolor.
  In pellentesque massa nulla amet neque diam dis aliquam. Arcu scelerisque fermentum diam dui.
  Sagittis id tristique fringilla commodo ipsum. Morbi velit maecenas mattis non felis sed. In interdum gravida quis nunc.
  Eu dictum quis pharetra sed. Enim at arcu sit sed eleifend tempus dignissim nunc vitae.
  Tempus nec ullamcorper scelerisque neque turpis in condimentum nulla donec. Ac id netus quam urna dictum orci imperdiet.
  Sagittis massa ipsum dolor ullamcorper consectetur placerat tortor dolor. In pellentesque massa nulla amet neque diam dis aliquam.
  Arcu scelerisque fermentum diam dui. Sagittis id tristique fringilla commodo ipsum. Morbi velit maecenas mattis non felis sed.
  In interdum gravida quis nunc.`,
});
